<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-md-12 col-lg-12">
          <c-card title="화학자재 기본정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-show="deleteEditable"
                  :url="deleteUrl"
                  :isSubmit="isDelete"
                  mappingType="DELETE"
                  label="LBLREMOVE"
                  icon="remove"
                  @beforeAction="removeReview"
                  @btnCallback="removeReviewCallback" />
                <c-btn
                  v-if="editable&&!disabled"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="review"
                  :mappingType="mappingType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="saveInfo"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-material
                  :required="true"
                  :editable="editable"
                  :disabled="disabled||isOld"
                  :data.sync="review"
                  :reviewCheck="true"
                  :isWrite="true"
                  label="자재코드/자재명(KOR)/자재명(ENG)"
                  name="materialCd"
                  type="codenameen"
                  v-model="review.materialCd">
                  <!-- @datachange="materialChange" -->
                </c-material>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
                <c-datepicker
                  :required="true" 
                  :editable="editable"
                  :disabled="disabled"
                  label="검토요청일"
                  name="reviewRequestDate"
                  v-model="review.reviewRequestDate"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                <c-multi-select
                  :required="true"
                  :editable="editable"
                  :disabled="disabled"
                  :isArray="false"
                  codeGroupCd="PLANT_CD"
                  itemText="codeName"
                  itemValue="code"
                  label="관련사업장"
                  name="plantCds"
                  v-model="review.plantCds">
                </c-multi-select>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
                <c-vendor
                  :editable="editable"
                  :disabled="disabled"
                  label="공급업체"
                  name="deliveryVendorCd"
                  v-model="review.deliveryVendorCd"
                  @vendorName="(val) => { review.deliveryVendorName = val }">
                </c-vendor>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
                <c-select
                  :editable="editable"
                  :disabled="disabled"
                  codeGroupCd="PROPERTIES_STATE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="propertiesStateCd"
                  label="성상"
                  v-model="review.propertiesStateCd"
                ></c-select>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
                <c-text
                  :editable="editable"
                  :disabled="disabled"
                  label="비산성(고체)"
                  name="fugacity"
                  v-model="review.fugacity">
                </c-text>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
                <c-text
                  :editable="editable"
                  :disabled="disabled"
                  label="끓는점(액체,기체)"
                  name="boilPoint"
                  v-model="review.boilPoint">
                </c-text>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
                <c-text
                  :editable="editable"
                  :disabled="disabled"
                  label="녹는점"
                  name="meltingPoint"
                  v-model="review.meltingPoint">
                </c-text>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
                <c-select
                  :editable="editable"
                  :disabled="disabled"
                  codeGroupCd="USAGE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="usageCd"
                  label="용도"
                  v-model="review.usageCd"
                ></c-select>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
                <c-text
                  :editable="editable && review.usageCd=='UC99999999'"
                  :disabled="disabled"
                  label="용도(기타)"
                  name="usageEtc"
                  v-model="review.usageEtc">
                </c-text>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
                <c-select
                  :editable="editable"
                  :disabled="disabled"
                  :comboItems="mixFlagItems"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="mixFlag"
                  label="제품구성"
                  v-model="review.mixFlag"
                ></c-select>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
                <c-text
                  :editable="editable"
                  :disabled="disabled"
                  label="CAS No."
                  name="casNo"
                  v-model="review.casNo">
                </c-text>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
                <c-text
                  :editable="editable"
                  :disabled="disabled"
                  label="투입 용해조"
                  name="inputDissolutionTank"
                  v-model="review.inputDissolutionTank">
                </c-text>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
                <c-select
                  :editable="editable"
                  :disabled="disabled"
                  codeGroupCd="PRODUCT_MANUF_CLASS_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  label="제품제조구분"
                  name="productManufClassCd"
                  v-model="review.productManufClassCd"
                ></c-select>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
                <c-select
                  :editable="editable"
                  :disabled="disabled"
                  codeGroupCd="CHEM_INGREDIENTS_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  label="구성성분 작성근거"
                  name="chemIngredientsCd"
                  v-model="review.chemIngredientsCd"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                <c-select
                  :editable="editable"
                  :disabled="disabled"
                  codeGroupCd="PRODUCT_CATEGORY_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  label="제품구분"
                  name="productCategoryCd"
                  v-model="review.productCategoryCd"
                ></c-select>
              </div>
              <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <c-textarea
                  :editable="editable"
                  :disabled="disabled"
                  :rows="2"
                  label="비고"
                  name="remarks"
                  v-model="review.remarks">
                </c-textarea>
              </div> -->
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-card class="cardClassDetailForm" :noHeader="true">
            <template slot="card-detail">
              <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3">
                <c-checkbox
                  :editable="editable"
                  :disabled="disabled"
                  :isFlag="true"
                  label="보관시설(창고) 유무"
                  name="keepFacilityFlag"
                  v-model="review.keepFacilityFlag"
                />
              </div>
              <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3">
                <c-text
                  :disabled="review.keepFacilityFlag==='N' || disabled"
                  :editable="editable"
                  type="number"
                  label="최대보관량(톤)"
                  name="maxKeepAmount"
                  v-model="review.maxKeepAmount"
                />
              </div>
              <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3">
                <c-checkbox
                  :editable="editable"
                  :disabled="disabled"
                  :isFlag="true"
                  label="저장시설(탱크) 유무"
                  name="storageFacilityFlag"
                  v-model="review.storageFacilityFlag"
                />
              </div>
              <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3">
                <c-text
                  :disabled="review.storageFacilityFlag==='N' || disabled"
                  :editable="editable"
                  type="number"
                  label="최대저장량(톤)"
                  name="maxStorageAmount"
                  v-model="review.maxStorageAmount"
                />
              </div>
              <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3">
                <c-checkbox
                  :editable="editable"
                  :disabled="disabled"
                  :isFlag="true"
                  label="제조·사용시설 여부"
                  name="manufacturingFacilitiesFlag"
                  v-model="review.manufacturingFacilitiesFlag"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9">
                <font class="formLabelTitle txtlabel">나노물질</font>
                <q-checkbox
                  :editable="editable"
                  :disabled="disabled"
                  v-model="review.nanoManufacturingFlag"
                  dense
                  color="orange-custom"
                  label="제조"
                  true-value="Y"
                  false-value="N"
                  class="customqcbox"
                />
                <q-checkbox
                  :editable="editable"
                  :disabled="disabled"
                  v-model="review.nanoIncomeFlag"
                  dense
                  color="orange-custom"
                  label="수입"
                  true-value="Y"
                  false-value="N"
                  class="customqcbox"
                />
                <q-checkbox
                  :editable="editable"
                  :disabled="disabled"
                  v-model="review.nanoNoneApplicableFlag"
                  dense
                  color="orange-custom"
                  label="해당없음"
                  true-value="Y"
                  false-value="N"
                  class="customqcbox"
                />
                <q-checkbox
                  :editable="editable"
                  :disabled="disabled"
                  v-model="review.nanoUnknownFlag"
                  dense
                  color="orange-custom"
                  label="모름"
                  true-value="Y"
                  false-value="N"
                  class="customqcbox"
                />
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-card class="cardClassDetailForm q-pb-sm" :noHeader="true">
            <template slot="card-detail">
              <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3">
                <c-checkbox
                  :editable="editable"
                  :disabled="disabled"
                  :isFlag="true"
                  label="인허가대상 물질 여부"
                  name="licensingFlag"
                  v-model="review.licensingFlag"
                />
              </div>
              <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3">
                <c-checkbox
                  :editable="editable"
                  :disabled="disabled"
                  :isFlag="true"
                  label="위험물 여부"
                  name="dangerFlag"
                  v-model="review.dangerFlag"
                />
              </div>
              <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3">
                <c-checkbox
                  :editable="editable"
                  :disabled="disabled"
                  :isFlag="true"
                  label="유해화학물질(PSM) 여부"
                  name="psmFlag"
                  v-model="review.psmFlag"
                />
              </div>
              <div class="col-12">
                <font class="formLabelTitle txtlabel">제품 분류 </font>
                <q-checkbox
                  :editable="editable"
                  :disabled="disabled"
                  v-model="review.makeFlag"
                  dense
                  color="orange-custom"
                  label="제조"
                  true-value="Y"
                  false-value="N"
                  class="customqcbox"
                />
                <q-checkbox
                  :editable="editable"
                  :disabled="disabled"
                  v-model="review.impFlag"
                  dense
                  color="orange-custom"
                  label="수입"
                  true-value="Y"
                  false-value="N"
                  class="customqcbox"
                />
                <q-checkbox
                  :editable="editable"
                  :disabled="disabled"
                  v-model="review.buyFlag"
                  dense
                  color="orange-custom"
                  label="구매"
                  true-value="Y"
                  false-value="N"
                  class="customqcbox"
                />
                <q-checkbox
                  :editable="editable"
                  :disabled="disabled"
                  v-model="review.usingFlag"
                  dense
                  color="orange-custom"
                  label="사용"
                  true-value="Y"
                  false-value="N"
                  class="customqcbox"
                />
                <q-checkbox
                  :editable="editable"
                  :disabled="disabled"
                  v-model="review.expFlag"
                  dense
                  color="orange-custom"
                  label="수출"
                  true-value="Y"
                  false-value="N"
                  class="customqcbox"
                />
                <q-checkbox
                  :editable="editable"
                  :disabled="disabled"
                  v-model="review.salesFlag"
                  dense
                  color="orange-custom"
                  label="판매"
                  true-value="Y"
                  false-value="N"
                  class="customqcbox"
                />
              </div>
              <div class="col-12">
                <font class="formLabelTitle txtlabel">유해화학물질 포함 여부 </font>
                <q-checkbox
                  :editable="editable"
                  :disabled="disabled"
                  v-model="review.toxicPoisonFlag"
                  dense
                  color="orange-custom"
                  label="유독물질"
                  true-value="Y"
                  false-value="N"
                  class="customqcbox"
                />
                <q-checkbox
                  :editable="editable"
                  :disabled="disabled"
                  v-model="review.toxicPermitFlag"
                  dense
                  color="orange-custom"
                  label="허가물질"
                  true-value="Y"
                  false-value="N"
                  class="customqcbox"
                />
                <q-checkbox
                  :editable="editable"
                  :disabled="disabled"
                  v-model="review.toxicLimitFlag"
                  dense
                  color="orange-custom"
                  label="제한물질"
                  true-value="Y"
                  false-value="N"
                  class="customqcbox"
                />
                <q-checkbox
                  :editable="editable"
                  :disabled="disabled"
                  v-model="review.toxicProhibitFlag"
                  dense
                  color="orange-custom"
                  label="금지물질"
                  true-value="Y"
                  false-value="N"
                  class="customqcbox"
                />
              </div>
            </template>
          </c-card>
        </div>
      </div>
    </q-form>
    <component
      :is="checkComponent"
      :popupParam="popupParam"
      :review.sync="review"
      :disabled.sync="disabled"
      :btnEditable.sync="btnEditable"
      :contentHeight.sync="contentHeight"
    />
  </div>
</template>

<script>
// import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'chem-review-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        mdmChemReviewId: '',
      }),
    },
    review: {
      mdmChemReviewId: '',  // 사전검토 일련번호
      reviewRequestDate: '',  // 검토요청일
      plantCds: '',  // 사업장코드
      materialCd: '',  // 자재코드
      materialName: '',  // 자재명(KOR)
      materialNameEn: '',  // 자재명(ENG)
      deliveryVendorCd: null,  // 공급업체 코드
      deliveryVendorName: '',  // 공급업체명
      mfgVendorCd: null,  // 제조업체 코드
      mfgVendorName: '',  // 제조업체명
      mdmChemReviewStepCd: null,  // 사전검토 진행상태
      propertiesStateCd: null,  // 성상-공통코드
      fugacity: '',  // 비산성(고체)
      boilPoint: '',  // 끊는점(액체,기체)
      meltingPoint: '',  // 녹는점
      moleWeight: '',  // 분자량
      usageCd: null,  // 용도-회사별 공통코드
      usageEtc: '',  // 용도 기타
      productCategoryCd: null,  // 제품구분
      keepFacilityFlag: 'N',  // 보관시설 유무
      maxKeepAmount: '',  // 최대보관량
      storageFacilityFlag: 'N',  // 저장시설 유무
      maxStorageAmount: '',  // 최대저장량
      manufacturingFacilitiesFlag: 'N',  // 제조 사용시설 여부
      nanoManufacturingFlag: 'N',  // 나노물질 제조 여부
      nanoIncomeFlag: 'N',  // 나노물질 수입 여부
      nanoNoneApplicableFlag: 'N',  // 나노물질 해당없음 여부
      nanoUnknownFlag: 'N',  // 나노물질 모름 여부
      inputDissolutionTank: '',  // 투입 용해조
      productManufClassCd: null,  // 제품제조구분 코드
      chemIngredientsCd: null,  // 구성성분 작성근거
      hsNo: '',  // HS번호
      specificGravity: '',  // 비중
      casNo: '',  // CAS No.
      serialNo: '',  // 고유번호(유독물번호or고시번호)
      keNo: '',  // KE 번호
      psmFlag: 'N',  // PSM 대상 여부
      mixFlag: 'N',  // 물질구성(단일 N/혼합 Y)
      licensingFlag: 'N',  // 인허가대상 물질 여부 Y/N
      dangerFlag: 'N',  // 위험물 여부Y/N
      makeFlag: 'N',  // 제품분류(제조)
      impFlag: 'N',  // 제품분류(수입)
      buyFlag: 'N',  // 제품분류(구매)
      usingFlag: 'N',  // 제품분류(사용)
      expFlag: 'N',  // 제품분류(수출)
      salesFlag: 'N',  // 제품분류(판매)
      toxicPoisonFlag: 'N',  // 유해화학물질포함여부(유독물질)
      toxicPermitFlag: 'N',  // 유해화학물질포함여부(허가물질)
      toxicLimitFlag: 'N',  // 유해화학물질포함여부(제한물질)
      toxicProhibitFlag: 'N',  // 유해화학물질포함여부(금지물질)
      remarks: '',  // 비고
      returnRemark: '',  // 반려사유
      regUserId: '',  // 등록자 ID
      chgUserId: '',  // 수정자 ID
      subs: [],
      prodReguls: [],
      checks: [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    btnEditable: {
      type: Boolean,
      default: false,
    },
    isOld: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      checkComponent:  () => import(`${'./chemReviewCheck.vue'}`),
      mixFlagItems: [
        { code: 'Y', codeName: '혼합' },
        { code: 'N', codeName: '단일' },
      ],
      editable: true,
      mappingType: 'POST',
      validUrl: '',
      saveUrl: '',
      deleteUrl: '',
      isSave: false,
      isDelete: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    deleteEditable() {
      return this.editable
        && !this.disabled
        && this.isOld
        && this.review.mdmChemReviewStepCd === 'MCR0000001'
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.validUrl = selectConfig.mdm.mam.review.valid.url;
      this.saveUrl = transactionConfig.mdm.mam.review.insert.url;
      this.deleteUrl = transactionConfig.mdm.mam.review.delete.url;
      // list setting
    },
    // materialChange(data) {
    //   if (data) {
    //     this.$set(this.review, 'materialName', data.materialName)
    //     this.$set(this.review, 'materialNameEn', data.materialNameEn)
    //   } else {
    //     this.$set(this.review, 'materialName', null)
    //     this.$set(this.review, 'materialNameEn', null)
    //   }
    //   /**
    //    * 자재 유효성 체크 (관리되고 있는 자재) : 해당 유효성 체크는 저장할 때 체크하도록 처리
    //    *  - 이미 사전평가를 진행했는지?
    //    *  - 이미 화학자재로 등록되어있는지?
    //    */
    // },
    saveInfo() {
      /**
       * 기본정보 및 검토자/결과 정보 저장
       */
      if (this.popupParam.mdmChemReviewId) {
        this.saveUrl = transactionConfig.mdm.mam.review.update.url;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.mdm.mam.review.insert.url;
        this.mappingType = 'POST';
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          if (this.$comm.validTable([{ required:true, name: 'mdmChemReviewClassName', label: '분야', }], this.review.checks, '검토 결과 목록')) {
            /**
             * 자재 유효성 체크 (관리되고 있는 자재) : 해당 유효성 체크는 저장할 때 체크하도록 처리
             *  - 이미 사전평가를 진행했는지?
             *  - 이미 화학자재로 등록되어있는지?
             */
            this.$http.url = this.validUrl
            this.$http.type = 'GET';
            this.$http.param = {
              materialCd: this.review.materialCd,
            };
            this.$http.request((_result) => {
              let _message = 'MSGSAVE'
              if (_result.data === 1) {
                _message = '해당 자재는 이미 사전평가를 진행했습니다.\n\r저장하시겠습니까?';
              } else if (_result.data === 2) {
                _message = '해당 자재는 이미 화학자재로 등록되어있습니다.\n\r저장하시겠습니까?';
              }
  
              window.getApp.$emit('CONFIRM', {
                title: 'LBLCONFIRM',
                message: _message,
                // TODO : 필요시 추가하세요.
                type: 'info', // success / info / warning / error
                // 확인 callback 함수
                confirmCallback: () => {
                  this.review.regUserId = this.$store.getters.user.userId;
                  this.review.chgUserId = this.$store.getters.user.userId;
  
                  this.isSave = !this.isSave;
                },
                // 취소 callback 함수
                cancelCallback: () => {
                },
              });
            },);
          }
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$set(this.popupParam, 'mdmChemReviewId', result.data)
      this.$emit('getDetail');
    },
    removeReview() {
      this.deleteUrl = this.$format(transactionConfig.mdm.mam.review.delete.url, this.popupParam.mdmChemReviewId);
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.isDelete = true;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    removeReviewCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('closePopup');
    },
  }
};
</script>